import React, { useState } from "react";
import About from './About';
import { GoDatabase } from "react-icons/go";
import noteData from './noteData';
//import HighlightIcon from "@material-ui/icons/Highlight";
import { BsInfoCircle } from "react-icons/bs";
import {T,PUC} from './PopUpJK';

var testDataIn = false;

function Header({setNotesArrays}) {
  const [about, setAbout] = useState(false);
  const [,setP] = PUC();

  const delNo= () => {};

  const delYes = () => {
    testDataIn = false;
    setNotesArrays([]);
    setP({option: 0, open:true, message:'All data deleted'});
  };

  const handleDel = () => {
    const msg = 'Wanna delete all the data?';
    setP({option: 1, open:true, message:msg, CBO:delYes, CBX: delNo});
  }

  const getTestData = () => {
    if (testDataIn) {
      handleDel();
    } else {
      testDataIn = true;
      function compare(a, b) {
        return a[1] < b[1] ? -1 : (a[1] > b[1] ? 1 : 0);
      }
      let tmp = [...noteData];
      tmp.sort(compare);
      setNotesArrays(tmp);
    }
  };

  return (
    <header style={{textAlign:'center'}}>
      <h1 style={{display:'flex', justifyContent:'center'}}>
        Note Threads
        &nbsp;
        <T 
          r={<button className="header-button" onClick={getTestData}>
               <GoDatabase />
             </button>} 
          s={testDataIn ? 'Remove data' : 'Get test data'}
          w={80} 
          t={40} 
        />
        &nbsp;
        <T r={<button className='header-button' onClick={()=>setAbout(true)}><BsInfoCircle /></button>} s='About' t={40} />
        <About about={about} setAbout={setAbout} />
      </h1>
    </header>
  );
}

export default Header;
