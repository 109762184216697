import { CiSaveDown2 } from "react-icons/ci";
import {nanoid} from 'nanoid';
import { useState } from "react";
import {T, PUC} from './PopUpJK';
import { LuShrink } from "react-icons/lu";
import { LuExpand } from "react-icons/lu";

function NewNote(props) {
    const [note, setNote] = useState({id: nanoid(), title: "", date:"", text: "", ttitle:''});
    const [isExpanded, setExpanded] = useState(true);
    const [p, setP] = PUC();

    function handleChange(event) {
        const { name, value } = event.target;
          const newNote = {...note, [name]: value};
          setNote(newNote);
    }

  const CBO = () => {
    if (note.ttitle.trim() === '' || note.title.trim() === '' || note.date.trim() === '')  {
      setP({...p, option: 0, open:true, message:'Fill in thread title/note title/date.'});
      return;
    }
    var newNotesArray = [nanoid(), '', 4, false];
    newNotesArray[1] = note.ttitle;
    newNotesArray.push(note);
    props.saveThread(newNotesArray);
    setNote({id: nanoid(), title: "", date:"", text: "", ttitle:''});
    setP({...p, option: 0, open:true, message:'Current note saved with a new thread.'});

  };

  const CBX = () => {
    setNote({id: nanoid(), title: "", date:"", text: "", ttitle:''});
    setP({...p, option: 0, open:true, message:'Current draft discarded.'});
  };

  function handleSave() {
    setP({...p, option: 1, open:true, message:'Wanna save the current note?', CBO:CBO, CBX:CBX});
  };

  return (
    <div className='newNote' style={{display:'flex', flexDirection:'column'}}>
      <div style={{display:'flex', justifyContent:'center'}}>
      <div style={{width:'100%'}} >New Note</div>  
      <T 
        r={
          <button onClick={isExpanded ? ()=>setExpanded(false) : ()=>setExpanded(true)}>
            {isExpanded ? <LuShrink /> : <LuExpand />}
          </button>
        }
        s={isExpanded ? 'shrink' : 'expand'}
      />
      </div>
     
    { isExpanded &&
     <>
      <input
            type='text'
            name="ttitle"
            onChange={handleChange}
            value={note.ttitle}
            placeholder="thread title"
      />
      <input
            type='text'
            name="title"
            onChange={handleChange}
            value={note.title}
            placeholder="note title"
      />
      <input
            type='date'
            name="date"
            onChange={handleChange}
            value={note.date}
            placeholder="MM/DD/YYYY"
      />     
      <textarea
          name="text"
          onChange={handleChange}        
          value={note.text}
          placeholder="text"
          rows={3}
      />
      <div style={{display:'flex', justifyContent:'space-between'}}>
        <span style={{fontSize:'10px'}}>Read/Write</span>
        < T 
          r={
            <button onClick={handleSave}>
              <CiSaveDown2 />
            </button>
          }
          s='save'
        />
      </div>
    </>
  } 
    </div>
  );
}

export default NewNote;
