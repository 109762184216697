import React from 'react';
import App from './Components/App';
import {PU} from './Components/PopUpJK';
import { createRoot } from 'react-dom/client';

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <PU>
      <App />
    </PU> 
  </React.StrictMode>,
);


