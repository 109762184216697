import './Pages.css';
//import testData from '../contactsData';
import { GoMoveToEnd } from "react-icons/go";
import { GrLinkNext } from "react-icons/gr";
import { GrLinkPrevious } from "react-icons/gr";
import { GoMoveToStart } from "react-icons/go";
import {T} from './PopUpJK';

function Pages({numPages, page, setPage, setNotesArrays}) {

  return (
   <>
      <div className='pageBar' style={{display:'flex', justifyContent:'space-between'}}>
        <span style={{fontSize:'12px'}}>Page bar</span>
        <div style={{display:'flex', justifyContent:'center'}}>
        {
          //numPages >= 2 &&
          <>
            { page > 1 &&
              <>
                &nbsp;
               < T r={<button className='page-button' title='First page' onClick={()=>setPage(0)}><GoMoveToStart /></button>} s='First page' />
              </>
            }
            { page > 0 &&
              <>
               &nbsp;
              <T r={<button className='page-button' onClick={()=>setPage(page=>page-1)}><GrLinkPrevious /></button>} s='Prev page' />
              </>
            }

            &nbsp;
            <T r={<span id='showPage' style={{color:'black'}}>{page + 1}</span>} s='current page' />
           
            &nbsp;
            < T r={<span id="totalCount" style={{color: 'white',}}>{numPages}</span>} s='total pages' />
            
            { page < numPages - 1 &&
              <>
               &nbsp;
               < T r={<button className='page-button' onClick={()=>setPage(page=>page+1)}><GrLinkNext /></button>} s='Next page' />
              </>
            }
            { page < numPages - 2 &&
              <>
              &nbsp;
              < T r={<button className='page-button' onClick={()=>setPage(numPages-1)}><GoMoveToEnd /></button>} s='Last page' />
              </>
            }         
          </>
        } 
        </div>
      </div>
      </>
  );
}
export default Pages;