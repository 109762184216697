import {nanoid} from 'nanoid';

const testData = [
    [nanoid(),'programming',6,false,
        {id:nanoid(),title:"JavaScript",date:"2024-02-09",text:"",ttitle: "" },
        {id:nanoid(),title:"C/C++",date:"2024-02-19",text:"",ttitle: "" },
        {id:nanoid(),title:"C#",date:"2024-02-29",text:"",ttitle: "" },
    ],
    [nanoid(),'food',7,false,
        {id:nanoid(),title:"burgers",date:"2024-02-09",text:"no regular drink",ttitle: "" },
        {id:nanoid(),title:"beef steaks",date:"2024-02-10",text:"medium-rare",ttitle: "" },
        {id:nanoid(),title:"pizzas",date:"2024-02-19",text:"Cheese pizza",ttitle: "" },
        {id:nanoid(),title:"potatoes",date:"2024-02-29",text:"boiled",ttitle: "" },
    ],
    [nanoid(),'medical visit',4,false,
        {id:nanoid(),title:"athlete foot",date:"2024-02-15",text:"see Dr.Johnson",ttitle: "" }
    ],
    [nanoid(),'reading',8,false,
        {id:nanoid(),title:"Selfish Genes",date:"2024-02-09",text:"through the end",ttitle: "" },
        {id:nanoid(),title:"The oldman and the sea",date:"2024-02-11",text:"first 5 chapters",ttitle: "" },
        {id:nanoid(),title:"The biography of Jesus Christ",date:"2024-02-15",text:"first 2 chapters",ttitle: "" },
        {id:nanoid(),title:"Chimpanzee Politics",date:"2024-02-16",text:"to the end",ttitle: "" },
        {id:nanoid(),title:"The tropics",date:"2024-02-20",text:"Preface",ttitle: "" },
    ],
    [nanoid(),'chores',4,false,
        {id:nanoid(),title:"cleaning",date:"2024-02-20",text:"kitchen area",ttitle: "" }
    ],
    [nanoid(),'random',9,false,
        {id:nanoid(),title:"aaa",date:"2024-01-02",text:"xxxxxxxxx",ttitle: "" },
        {id:nanoid(),title:"bbb",date:"2024-01-05",text:"yyyyyyyyyyy",ttitle: "" },
        {id:nanoid(),title:"ccc",date:"2024-01-07",text:"zzzzzzzzzzz",ttitle: "" },
        {id:nanoid(),title:"ddd",date:"2024-01-08",text:"hhhhhhhhh",ttitle: "" },
        {id:nanoid(),title:"eee",date:"2024-01-15",text:"iiiiiiiiiii",ttitle: "" },
        {id:nanoid(),title:"ggg",date:"2024-01-17",text:"jjjjjjjjjjj",ttitle: "" },
    ],
   
];

export default testData;