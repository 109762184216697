import React, { useState } from "react";
import Header from "./Header";
import Notes from './Notes';
import './App.css';
import NewNote from "./NewNote";
import ThreadsSearch from './Search';
import Pages from './Pages';

const pageSize = 5;

function App() {
  const [notesArrays, setNotesArrays] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [category, setCategory] = useState("ttitle");
  const [page, setPage] = useState(0);

  function deleteThread(tid) {
    let lessNotesArrays = notesArrays.filter(na => na[0] !== tid);
    setNotesArrays(lessNotesArrays);
  };

  function saveThread(notesArray) {
    function compare(a, b) {
      return a[1] < b[1] ? -1 : (a[1] > b[1] ? 1 : 0);
    }
    let tmpItems = notesArrays.filter(na =>  (na[0] !== notesArray[0]));
    tmpItems.push(notesArray);
    tmpItems.sort(compare);
    setNotesArrays(tmpItems);
  };
 
  const filterData = () => {
    switch(category) {
      case "ttitle":
        return notesArrays.filter(t => 
          t[1].toLocaleLowerCase()
          .includes(filterText.toLocaleLowerCase()));
      case "title": case "date": case "text":
        return notesArrays.filter(t => 
          t.filter(n => (typeof n === 'object' 
            && n[category].toLocaleLowerCase()
            .includes(filterText.toLocaleLowerCase())
            .length > 0)));
      default:
        return notesArrays;
    }
  };
  
  let Threads = filterData();

  const numPages = Math.ceil(Threads.length / pageSize);

  const pageNum = (page >= numPages || page < 0) ? 0 : page;
  const pageData = Threads.slice(pageNum * pageSize, pageNum * pageSize + pageSize);

  return (
    <div style={{display:'flex',flexDirection:'column', alignItems:'center'}}>
      <Header setNotesArrays={setNotesArrays} />
      <
        NewNote
        saveThread={saveThread} 
      />
      {
        notesArrays.length > 0 &&
        <
          ThreadsSearch  
          filterText={filterText} 
          setFilterText={setFilterText} 
          category={category} 
          setCategory={setCategory} 
        />
      } 
      {
        Threads.length > 0 &&
      <>
      <
        Pages 
        numPages={numPages} 
        page={pageNum} 
        setPage={setPage} 
        setNotesArrays={setNotesArrays} 
      />
      </>
      }
      {
        pageData.length > 0 &&
        pageData.map(thread => 
          <
            Notes 
            key={thread[0]}
            notesArray={thread} 
            deleteThread={deleteThread} 
            saveThread={saveThread} 
          />
        )
      }
      
    </div>  
  );
}
    
export default App;
