
import {T} from './PopUpJK';

function ThreadsSearch({ filterText, setFilterText, category, setCategory}) {
  return (
    <div className='searchBar' style={{display:'flex', justifyContent:'space-between'}}>
      <span style={{fontSize:'12px'}}>Search bar</span>
      <div style={{width:'80%', display:'flex', justifyContent:'center'}} >
        <input
          placeholder="categoried search"
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
          style={{width:'50%'}}
        />
        < T r={ 
        <
          select 
          id='searchSelect' 
          value={category} 
          onChange={(e) => setCategory(e.target.value)}
        >
          <option value="0" disabled>Select category</option>
          <option value="ttitle">Thread Title</option>
          <option value="title">Note Title</option>
          <option value="date">Date (ex: 2024-02-29)</option>
          <option value="text">Text</option>
        </select>
      }
      s='search category'
      />
      </div>
       
    </div>
     
  );
}
export default ThreadsSearch;