import React, {useState} from "react";
import Buttons from "./Buttons";
import { CiSaveDown2 } from "react-icons/ci";
import { LuShrink } from "react-icons/lu";
import { LuExpand } from "react-icons/lu";
import {T, PUC} from './PopUpJK';

function Notes(props) {
    const [notesArray, setNotesArray] = useState(props.notesArray);
    const [isExpanded, setExpanded] = useState(props.notesArray[3]);
    const [isReadOnly, setIsReadOnly] = useState(true);
    const [p, setP] = PUC();

    const note = notesArray[notesArray[2]];
 
    function expand() {
      const newNotesArray = [...notesArray];
      newNotesArray[3] = true;
      setExpanded(newNotesArray[3]);
      setNotesArray(newNotesArray);
    }

    function handleChange(event) {
        const { name, value } = event.target;
        const newNote = {...note, [name]: value};
        let newNotesArray = [...notesArray];
        newNotesArray[newNotesArray[2]] = newNote;
        setNotesArray(newNotesArray);
    }
  
    const saveNotesArray = (discard, expand) => {
      let newNotesArray = [...notesArray];
      if (discard) {
        newNotesArray.pop();
        newNotesArray[2] = newNotesArray.length - 1;
      } 
      if (! expand) {
        newNotesArray[3] = false;
        setExpanded(newNotesArray[3]);
      }
      setIsReadOnly(true);  
      setNotesArray(newNotesArray);
      props.saveThread(newNotesArray);
    };

    const CBO =() => {
      if (note.title.trim() === '' || note.date.trim() === '')  {
        setP({...p, option: 0, open:true, message:'Fill in title and date.'});
        return;
      }  
      saveNotesArray(false, true); 
      setP({...p, option: 0, open:true, message:'Current note saved.'});
    };
    
    const CBX =() => {
      saveNotesArray(true, true);
      setP({option: 0, open:true, message:'Current draft discarded.'});
    };

    function handleSave() {
      setP({option: 1, open:true, message:'Wanna save the current note?', CBO:CBO, CBX: CBX});
    };

    const CBO2 =() => {
      if (note.title.trim() === '' || note.date.trim() === '')  {
        setP({option: 0, open:true, message:'Fill in title and date.'});
        return;
      }   
      saveNotesArray(false, false);
      setP({option: 0, open:true, message:'Current note saved.'});
    };

    const CBX2 =() => {
      saveNotesArray(true, false);
    };

    function handleSet() {
      if (isReadOnly) {
        saveNotesArray(false, false);
      } else {
        setP({option: 1, open:true, message:'Wanna save the current note?', CBO:CBO2, CBX: CBX2});
      }
    }

  return (
    <div className='oldNote' style={{display:'flex', flexDirection:'column'}}>
      <div style={{display:'flex', justifyContent:'center'}}>
        <div style={{width:'100%'}} >{notesArray[1]}</div>
      <T 
        r={
          <button onClick={isExpanded ? handleSet : expand}>
            {isExpanded ? <LuShrink /> : <LuExpand />}
          </button>
        }
        s={isExpanded ? 'shrink' : 'expand'}
      />
      </div>
    {
      isExpanded &&
      <>
        <div style={{display:'flex', flexDirection:'column'}}>
          <input
            type='text'
            name="title"
            onChange={handleChange}
            value={note.title}
            placeholder="title"
            readOnly={isReadOnly}
          />
          <input
            type='date'
            name="date"
            onChange={handleChange}
            value={note.date}
            placeholder="MM/DD/YYYY"
            readOnly={isReadOnly}
          />     
          <
            textarea
            name="text"
            onChange={handleChange}        
            value={note.text}
            placeholder="text"
            rows={3}
            readOnly={isReadOnly}
          />
        </div>
      
      { isReadOnly && 
       <div style={{display:'flex', justifyContent:'space-between'}}>
          <span style={{fontSize:'10px'}}>Read only</span> 
          <
            Buttons 
            notesArray={notesArray}
            setNotesArray={setNotesArray}
            setIsReadOnly={setIsReadOnly}
            setExpanded={setExpanded}
            deleteThread={props.deleteThread}
            saveThread={props.saveThread}
          />
        </div>
      }
      {! isReadOnly &&
         <div style={{display:'flex', justifyContent:'space-between'}}>
          <span style={{fontSize:'10px'}}>Read/Write</span> 
          <div style={{display:'flex', justifyContent:'center'}}>
            <T r={<div style={{width:'20px'}}>{notesArray[2] - 3}</div>} s={'Current note'} w={100} />
            :
            <T r={<div style={{width:'20px'}}>{notesArray.length - 4}</div>} s={'Total notes'} w={80} />
            <T r={<button onClick={handleSave}><CiSaveDown2 /></button>} s='save' />
          </div>
        </div>
      }
      </>
    }
    </div>
  );
}

export default Notes;
