const aboutText = 
<>
This is an application which makes and manages note threads (sequences of related notes). <br />
1. The 'New Note' textarea (having blue bar) creates a note thread with one new note. <br />
The newly created thread is shown below the 'New Note' textarea.<br />
2. In order to test this app, use test data. Press the "Get test data" button beside the web page title.<br />
3. You can add or delete notes with the note thread textarea (having yellow bar).<br />
  Notes are readonly.  They can be only deleted or added to. <br />
  Newly created note is shown as the last note.<br />
4. Page managing bar is provided.  Each page contains 5 threads and those threads are sorted alphabetically.<br />
4. Search function is provided to select a group of threads. <br />
Search function is used in terms of Thread titles, note titles, dates, and text. <br /> 
</>;

export default aboutText;