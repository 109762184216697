import React from "react";
import { nanoid } from 'nanoid';
import { GrCaretPrevious } from "react-icons/gr";
import { TbPlayerTrackPrev } from "react-icons/tb";
import { TbPlayerTrackNext } from "react-icons/tb";
import { GrCaretNext } from "react-icons/gr";
import { FaRegEdit } from "react-icons/fa";
import { RiDeleteBin2Line } from "react-icons/ri";
import { MdDeleteForever } from "react-icons/md";
import {T, PUC} from './PopUpJK';

export default function Buttons(props) {
    const [, setP] = PUC();

    const {notesArray, setNotesArray, setIsReadOnly, deleteThread, saveThread} = props;

    const note = notesArray[notesArray[2]];

    function setupNote (n) {
        let newNote = {...n};
        newNote.id = nanoid();
        let newNotesArray = [...notesArray];
        newNotesArray.push(newNote);
        newNotesArray[2] = newNotesArray.length - 1;
        setNotesArray(newNotesArray);
        saveThread(newNotesArray);
      };

      const CBO1 = () => {
        setupNote (note); 
      };
    
      const CBX1 = () => {
        setupNote ({id: '', title: "", date:"", text: "", ttitle:''});
      };

      function makeEdittable() {
        setIsReadOnly(false);
        const msg = 'Press "O" to edit a copy of the current note.\n'
        + '\nPress "X" to create a new note.';
        setP({option: 1, open:true, message:msg, CBO:CBO1, CBX: CBX1});
      }
     
      const CBO4 = () => {
        deleteThread(notesArray[0]); 
        setP({option: 0, open:true, message:'Current thread deleted.'});
      };
    
    const CBX4 = () => { };

    const CBO0 = () => {
      if (notesArray.length === 5) { 
        const msg = 'Only one note left. If you delete this note, '
          +'the whole thread will be removed.\n'
          + 'Wanna delete the current note?'; 
        setP({option: 1, open:true, message:msg, CBO:CBO4, CBX: CBX4})
      } else {
        let newNotesArray = notesArray.filter(n => (typeof n !== 'object' || n.id !== note.id));
        if (newNotesArray[2] === newNotesArray.length) {
          newNotesArray[2] -= 1;
        }
        setNotesArray(newNotesArray);
        saveThread(newNotesArray);
        setP({option: 0, open:true, message:'Current note deleted.'});
      }
    };
  
    const CBX0 = () => { };

    function handleNoteDel() {
      let msg = 'Wanna delete the current note?';
      setP({option: 1, open:true, message:msg, CBO:CBO0, CBX: CBX0});
    }

  const CBO3 = () => {
    deleteThread(notesArray[0]); 
    setP({option: 0, open:true, message:'Current thread deleted.'});
  };

  const CBX3 = () => {};

  function handleThreadDel() {
    setP({option: 1, open:true, message:'Wanna delete the whole thread?', CBO:CBO3, CBX: CBX3});
  }

      function handleFirst() {
        let newNotesArray = [...notesArray];
        newNotesArray[2] = 4;
        setNotesArray(newNotesArray);
      }
    
      function handleLast() {
        let newNotesArray = [...notesArray];
        newNotesArray[2] = newNotesArray.length - 1;
        setNotesArray(newNotesArray);
      }

    function handlePrev() {
      let newNotesArray = [...notesArray];
      newNotesArray[2] -= 1;
      setNotesArray(newNotesArray);
    }
  
    function handleNext() {
      let newNotesArray = [...notesArray];
      newNotesArray[2] += 1;
      setNotesArray(newNotesArray);
    }

  return (
      <div  style={{display:'flex', justifyContent:'center'}}>
        {
          (notesArray[2] > 5) &&
          <>
            &nbsp;
            <T 
              r={<button onClick={handleFirst}><TbPlayerTrackPrev /></button>} 
              s='first note'
              t={20}
            />
          </>
        }
       
        {
          (notesArray[2] > 4) &&
          <>
            &nbsp;
            <T 
              r={<button onClick={handlePrev}><GrCaretPrevious /></button>} 
              s='prev note'
            />
          </>
        }
        &nbsp;
        <T r={<div style={{width:'20px'}}>{notesArray[2] - 3}</div>} s={'Current note'} w={80} />
        :
        <T r={<div style={{width:'20px'}}>{notesArray.length - 4}</div>} s={'Total notes'} w={80} />
        {
          (notesArray[2] < notesArray.length - 1) &&
          <>
            &nbsp;
            <T 
              r={<button onClick={handleNext}><GrCaretNext /></button>} 
              s='next note'
            />
          </>
        }
        {
          (notesArray[2] < notesArray.length - 2) &&
          <>
            &nbsp;
            <T 
              r={<button onClick={handleLast}><TbPlayerTrackNext /></button>} 
              s='last note'
            />
          </>
        }

        &nbsp;
        <T 
          r={<button onClick={makeEdittable}><FaRegEdit /></button>} 
          s='edit' 
        />

        &nbsp;
        <T 
          r={<button onClick={handleNoteDel}><MdDeleteForever /></button>} 
          s='delete note'
        /> 

        &nbsp;
        <T 
          r={<button onClick={handleThreadDel}><RiDeleteBin2Line /></button>} 
          s='delete thread'
        />  
      </div>
  );
}
